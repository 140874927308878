'use client';
import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import { Logo, Button } from 'mmds';
import Divider from './components/ui/divider/divider';
import Link from 'next/link';

export default function GlobalError({ error, reset }) {
    useEffect(() => {
        captureException(error);
    }, [error]);

    return (
        <html>
            <body>
                <div className="globalErrorRoot">
                    <Link href="/" prefetch={false}>
                        <Logo />
                    </Link>
                    <Divider />

                    <div className="messageSection">
                        <h1 className="messageH1">SORRY</h1>
                        <p className="messageP">
                            Something unexpected happened.
                        </p>
                        <Button
                            variant="text"
                            label="Try again"
                            onClick={reset}
                        />
                    </div>
                    <Link
                        prefetch={false}
                        className="supportLinkStyle"
                        href="https://support.maap.cc/hc/en-us"
                    >
                        Customer Support
                    </Link>
                    <div className="featuresStyle">
                        <div className="featureStyle firstFeatureStyle">
                            <Link
                                className="featureLinkStyle"
                                href={`/collections/maap-new-arrivals`}
                            >
                                SHOP MAN
                            </Link>
                        </div>
                        <div className="featureStyle lastFeatureStyle">
                            <Link
                                className="featureLinkStyle"
                                href={`/collections/new-arrivals-woman`}
                            >
                                SHOP WOMAN
                            </Link>
                        </div>
                    </div>
                </div>
            </body>
            {/* Resorting to JSX styles because component styles are not available in this error state */}
            <style jsx global>
                {`
                    .globalErrorRoot {
                        padding-block-start: var(--spacing-size-six);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .messageSection {
                        margin: 180px 0;
                        text-align: center;
                        display: grid;
                    }

                    .messageH1 {
                        font-size: 1.875rem;
                        font-family: 'MonumentExtended-Regular', sans-serif;
                    }

                    .messageP {
                        line-height: 1.5em;
                        margin: 5px 0;
                        font-size: 0.95rem;
                        color: rgb(34, 34, 34);
                    }

                    .supportLinkStyle {
                        font-size: 1.125rem;
                        margin: 15px 0 40px;
                        font-weight: 300;
                        color: rgb(34, 34, 34);
                        white-space: nowrap;
                        line-height: 1.5em;
                        text-underline-offset: 4px;
                        text-decoration-thickness: 1px;
                    }

                    .featuresStyle {
                        width: 100%;
                        display: flex;
                        font-size: 2.5rem;
                        font-family: 'MonumentExtended-Regular', sans-serif;
                        font-weight: 600;
                    }

                    .featureStyle {
                        flex: 1 0 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: rgb(255, 255, 255);
                    }

                    .featureLinkStyle {
                        color: rgb(255, 255, 255);
                        text-decoration: none;
                        text-align: center;
                        padding: 85px;
                        width: 100%;
                    }

                    .firstFeatureStyle {
                        background-color: rgb(151, 169, 133);
                    }

                    .lastFeatureStyle {
                        background-color: rgb(204, 203, 198);
                    }

                    @media screen and (max-width: 991px) {
                        .featuresStyle {
                            flex-direction: column;
                        }
                    }
                `}
            </style>
        </html>
    );
}
